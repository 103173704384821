import React from 'react';
import Table from 'react-bootstrap/Table'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'


let deviceName = [];
let loaction = [];

const columnHeader = ["Marker ID", "Space ID", "Device Type", "Name", "Battery", "Updated", "Calibartion Status", "Calibartion Link",];

const auth_key = "eyJhbGciOiJIUzUxMiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiNDA4NTAiLCJleHAiOjE2NTQ3Njg4NjIsImFpZCI6MTIsImlhdCI6MTY1NDE2Mzc2Mn0.y0DvH4yp7OL85GwyQu4G41UyAB5IFYKXK3CQeDaheocVndLUb6l6USMOPE_G2QypOGWMldSZf_Pi22tDRg0WPw"
class Devicetable extends React.Component {
    constructor(props) {
        super(props);
        var today = new Date(),
            date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
        this.state = {
            date: date,
            isVerified: 'Not Calibarted',
            devicetable: true,
            floorplane: false
        };
        this.generateHeader = this.generateHeader.bind(this);
        this.generateTableData = this.generateTableData.bind(this);
    }

    XYverify(name, spaceId) {
        this.props.updateBuldingname(name);
        this.props.slecteddevice(spaceId)
        this.props.setroute('XYverification');
    }

    Backfrompg() {
        this.props.setroute('EnterfloorID');
    }

    Backfromfloorplane = () => {
        this.setState(
            {
                devicetable: true,
                floorplane: false
            }
        )
    }

    onClickfloormap = () => {
        this.setState(
            {
                devicetable: false,
                floorplane: true
            }
        )
    }

    renderfloorplane() {
        let image_url = loaction.image.url;
        console.log(image_url)
        return (
            <div>
                <div className="text-center" style={{ marginTop: 20, marginBottom: 20 }}>
                    <button type="button" className="btn genericButton  btn-lg btn-danger rounded" onClick={() => this.Backfromfloorplane()} ><i className="fas fa-arrow-circle-left"></i> Back</button>
                </div>
                <div style={{ marginBottom: 20 }}>
                    <img src={image_url} alt="" height="1000" ></img>
                </div>
            </div>
        )
    }

    generateHeader() {
        let res = [];
        for (var i = 0; i < columnHeader.length; i++) {
            res.push(
                <th key={columnHeader[i]}>{columnHeader[i]}</th>)
        }
        return res;
    }

    generateTableData() {
        let res = [];
        let devicetype = "";
        for (var i = 0; i < deviceName.length; i++) {
            if (deviceName[i].assignedSpace && deviceName[i].assignedSpace.serial < 500) {
                devicetype = "TIM";
                let roomname = [loaction.anchorAddress, " - ", deviceName[i].displayName];
                let selectedDevice = deviceName[i].assignedSpace.id;
                res.push(
                    <tr key={i + 0}>
                        <td >{deviceName[i].assignedSpace.serial}</td>
                        <td >{deviceName[i].assignedSpace.id}</td>
                        <td >{devicetype}</td>
                        <td >{deviceName[i].displayName}</td>
                        <td >{deviceName[i].batteryVoltage}</td>
                        <td><a href="https://tim-processor.afreespace.com/30000c2a691e5720">{deviceName[i].lastUpdated}</a></td>
                        <td >{this.state.isVerified}</td>
                        <td >
                            <div style={{ color: 'blue' }} onClick={() => this.XYverify(roomname, selectedDevice)}>
                                <u className='u'>Calibrate</u>
                            </div>
                        </td>
                    </tr >
                )
            }
        }
        return res;
    }

    devicetablelist() {
        return (
            <div >
                <Table bordered hover style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                    <caption>List of devices</caption>
                    <thead className="thead-dark">
                        <tr >
                            {this.generateHeader()}
                        </tr>
                    </thead>
                    <tbody >
                        {this.generateTableData()}
                    </tbody>
                </Table>
                <ButtonToolbar className="justify-content-center" style={{ columnGap: 30 }}>
                    <button type="button" className="btn genericButton  btn-lg btn-danger rounded" onClick={() => this.Backfrompg()} ><i className="fas fa-arrow-circle-left"></i> Back</button>
                    <button type="button" className="btn genericButton  btn-lg btn-success rounded" onClick={() => this.onClickfloormap()}>Floor Map <i className="fas fa-map-marked-alt"></i></button>
                </ButtonToolbar>
            </div >
        )
    }

    componentDidMount() { // create the interval once component is mounted
        const { floorId } = this.props;
        fetch(`https://aws.afreespace.com/api/admin/locations/${floorId}/devices`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-auth-key': auth_key
            }
        })
            .then(resp => {
                return resp.json();
            })
            .then(data => {
                deviceName = data;
                console.log(deviceName);
            })
            .catch(console.log)
        fetch(`https://aws.afreespace.com/api/admin/locations/${floorId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-auth-key': auth_key
            }
        })
            .then(resp => {
                return resp.json();
            })
            .then(data => {
                loaction = data;
                console.log(loaction);
                this.props.updateBuldingname(loaction.anchorAddress);
            })
            .catch(console.log)
    }

    render() {
        const { devicetable, floorplane } = this.state
        return (
            <div>
                {devicetable ? this.devicetablelist() : ""}
                {floorplane ? this.renderfloorplane() : ""}
            </div>
        )
    }
}

export default Devicetable;