import React from 'react';
import MR from './MR1.jpg'
import Grid from './grid -view.svg'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
class calibrateTiM extends React.Component {
    constructor() {
        super();
        this.state = {
            startSeries: '',
            endSeries: '',
            loadingCali: true,
            minutes: 0,
            seconds: 5,
            renderConfirmvcant: true,
            calibrateTim: false,
        };
    }

    calibrate() {
        this.setState({ loadingCali: false, calibrateTim: true }, () => {
        });
        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state
            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    Backfrompg() {
        this.setState({ calibrateTim: false }, () => {
        });
        this.props.setroute('DragAndDropPostion');
    }

    Procesed() {
        this.props.setroute('test');
    }

    calibrateAgain() {
        this.setState({ renderConfirmvcant: true, calibrateTim: false }, () => {
        });
    }

    renderontimerexpry() {
        return (
            <div>
                <h3 style={{ textAlign: "center", color: "green", marginTop: 40, }}>Calibration successful. Now you are ready for testing.</h3>
                <ButtonToolbar className="justify-content-center" style={{ columnGap: 30, marginTop: 30 }}>
                    <button type="button" className="btn genericButton  btn-lg btn-danger rounded" onClick={() => this.calibrateAgain()}>Calibrate Again <i className="fas fa-cogs"></i></button>
                    <button type="button" className="btn genericButton  btn-lg btn-success rounded" onClick={() => this.Procesed()}>Next <i className="fas fa-walking"></i></button>
                </ButtonToolbar>
            </div >
        )
    }

    backcalliberate() {
        this.setState({
            renderConfirmvcant: true
        })
    }

    rendertimer() {
        return (
            <div>
                <h3 style={{ textAlign: "center", color: "DodgerBlue", marginTop: 40, }}>Please click below to calibrate TIM. Please ensure room is vacant during calibration.</h3>
                <ButtonToolbar className="justify-content-center" style={{ columnGap: 30, marginTop: 30 }}>
                    <button type="button" className="btn genericButton  btn-lg btn-danger rounded" onClick={() => this.backcalliberate()}><i className="fas fa-arrow-circle-left"></i> Back</button>
                    <button type="button" className="btn genericButton  btn-lg btn-primary rounded" onClick={() => this.calibrate()}>calibrate TiM <i className="fas fa-cogs"></i></button>
                </ButtonToolbar>
                {this.state.calibrateTim ?
                    <div >
                        <h3 style={{ textAlign: "center", color: "red", marginTop: 20 }}><i>Calibrating TiM . . .</i>
                            <div className="spinner-border text-danger" style={{ marginTop: 8, marginLeft: 40, }}></div>
                        </h3>
                    </div>
                    :
                    ""
                }
            </div>
        )
    }

    confirmvacant() {
        this.setState({
            renderConfirmvcant: false,
            minutes: 0,
            seconds: 5,
        })
    }

    renderConfirmvcant() {
        return (
            <div>
                <h3 style={{ textAlign: "center", color: "DodgerBlue", marginTop: 40, }}> Please make sure room is vacant</h3>
                <ButtonToolbar className="justify-content-center" style={{ columnGap: 30, marginTop: 30 }}>
                    <button type="button" className="btn genericButton  btn-lg btn-danger rounded" onClick={() => this.Backfrompg()}><i className="fas fa-arrow-circle-left"></i> Back</button>
                    <button type="button" className="btn genericButton  btn-lg btn-success rounded" onClick={() => this.confirmvacant()}>Confirm vacant <i className="fas fa-check-circle"></i></button>
                </ButtonToolbar>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.state.renderConfirmvcant ? this.renderConfirmvcant()
                    :
                    <div>
                        {this.state.minutes === 0 && this.state.seconds === 0 ? this.renderontimerexpry() : this.rendertimer()}
                    </div>
                }
                <div className="container">
                    <img src={MR} alt="" height="500px" style={{ padding: 20, opacity: 1 }} ></img>
                    <div className="middle">
                        {/* <img style={{ transform: `rotate(${localStorage.getItem('rotation')}deg)` }} src={TiMdeviceicon} alt="" height="50px"></img> */}
                        <img src={Grid} alt="" height="450px" style={{ transform: `rotate(${-localStorage.getItem('rotation')}deg)`, opacity: 0.5 }}></img>
                    </div>
                </div>
            </div>
        )
    }
}
export default calibrateTiM;