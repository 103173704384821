import React from 'react';
import MR from './MR1.jpg';
import Grid from './grid -view.svg'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
class test extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingCali: true,
            minutes: 0,
            seconds: 5,
            percentage: 50,
            totalnumberofpostion: 6,
            cureentpostion: 1,
            postion: [
                {
                    id: 1,
                    status: "",
                    count: 1,
                    coordinates:
                    {
                        x: 336,
                        y: 194
                    }

                },
                {
                    id: 2,
                    status: "",
                    count: 1,
                    coordinates:
                    {
                        x: 275,
                        y: 305
                    }

                },
                {
                    id: 3,
                    status: "",
                    count: 1,
                    coordinates:
                    {
                        x: 225,
                        y: 305
                    }

                },
                {
                    id: 4,
                    status: "",
                    count: 1,
                    coordinates:
                    {
                        x: 165,
                        y: 250
                    }

                },
                {
                    id: 5,
                    status: "",
                    count: 1,
                    coordinates:
                    {
                        x: 165,
                        y: 189
                    }

                },
                {
                    id: 6,
                    status: "",
                    count: 1,
                    coordinates:
                    {
                        x: 258,
                        y: 135
                    }

                },
            ],

            postionnumber: 0,
            attempts: 1,
            maxattempts: 3,

            attemptsOfrequest: 1,
            maxattemptsOfrequest: 3,

            ready: true,
            show: true,
            onclickiamthere: false,
            statusofcount: 'present', //present,absent,sessiontimeout'
        }
    }

    changeState() {
        this.setState({ loadingCali: false, onclickiamthere: true }, () => {
        });
        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state
            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    renderSummary = () => {
        this.props.setroute('Summary');
    }

    rendertimer() {
        const { postion, onclickiamthere, postionnumber } = this.state;
        return (
            <div>
                {
                    onclickiamthere ?
                        <div >
                            <h3 style={{ textAlign: "center", color: "red", marginTop: 20 }}><i>Processing for Position {postion[postionnumber].id} . . .</i><div className="spinner-border text-danger" style={{ marginTop: 8, marginLeft: 40, }}></div></h3>
                        </div>
                        :
                        <div>
                        </div>
                }

            </div >
        )
    }

    handleClosepresent = () => {
        if (this.state.postion[this.state.postionnumber].id < this.state.totalnumberofpostion) {
            const increment = this.state.postionnumber + 1;
            this.setState({ onclickiamthere: false, minutes: 0, seconds: 5, postionnumber: increment, ready: true }, () => {
            });
        }
    }

    handleCloseabsent = () => {
        const incrementattempts = this.state.attempts + 1;
        this.setState({ onclickiamthere: false, minutes: 0, seconds: 5, attempts: incrementattempts, ready: true }, () => {
        });
    }

    handleCloserequestdeny = () => {
        const incrementattempts = this.state.attemptsOfrequest + 1;
        this.setState({ onclickiamthere: false, minutes: 0, seconds: 5, attemptsOfrequest: incrementattempts, ready: true }, () => {
        });
    }

    renderontimerexpired(pagekey) {
        const { postion, show, percentage, totalnumberofpostion, attempts, maxattempts, attemptsOfrequest, maxattemptsOfrequest, postionnumber } = this.state;
        let componet = "";
        switch (pagekey) {
            case 'present':
                componet = < div >
                    < Modal show={show} backdrop="static" size="md" aria-labelledby="contained-modal-title-vcenter" centered onHide={this.handleClose} >
                        <Modal.Body>
                            <h4 style={{ textAlign: "center", color: "green" }}><i className="far fa-check-circle"></i> SUCCESSFUL </h4>
                            {
                                (postion[postionnumber].id > ((percentage / 100) * totalnumberofpostion) && postion[postionnumber].id !== totalnumberofpostion) ?
                                    <h4 style={{ textAlign: "center", color: "green" }}>Calibration Completed</h4>
                                    :
                                    <div>
                                        {
                                            (postion[postionnumber].id === totalnumberofpostion) ?
                                                <h4 style={{ textAlign: "center", color: "green" }}>Calibration Completed</h4>
                                                :
                                                <h4 style={{ textAlign: "center", color: "green" }}>Number of Positions captured - {postion[postionnumber].id}</h4>
                                        }
                                    </div>
                            }

                        </Modal.Body>
                        <Modal.Footer>
                            {
                                (postion[postionnumber].id > ((percentage / 100) * totalnumberofpostion) && postion[postionnumber].id !== totalnumberofpostion) ?
                                    <div className="btn-toolbar" style={{ columnGap: 30 }}>
                                        <Button variant="primary" onClick={this.handleClosepresent}>
                                            <i className="fas fa-arrow-circle-left"></i> Test More Position
                                        </Button>
                                        <Button variant="success" onClick={this.renderSummary}>
                                            Done <i className="fas fa-check-circle"></i>
                                        </Button>
                                    </div>
                                    :
                                    <div>
                                        {postion[postionnumber].id === totalnumberofpostion ?
                                            <Button variant="success" onClick={this.renderSummary}>
                                                Done <i className="fas fa-check-circle"></i>
                                            </Button>
                                            :
                                            <Button variant="primary" onClick={this.handleClosepresent}>
                                                Next Position <i className="fas fa-arrow-alt-circle-right"></i>
                                            </Button>
                                        }
                                    </div>
                            }
                        </Modal.Footer>
                    </Modal >
                </div >
                break;
            case 'absent':
                componet = < div >
                    < Modal show={show} backdrop="static" size="md" aria-labelledby="contained-modal-title-vcenter" centered onHide={this.handleClose} >
                        <Modal.Body>
                            <h4 style={{ textAlign: "center", color: "red" }}><i className="fas fa-exclamation-triangle"></i> ERROR : Attempt - {attempts}/{maxattempts}</h4>
                            <h4 style={{ textAlign: "center", color: "red" }}>Device is unable to locate you </h4></Modal.Body>
                        <Modal.Footer>
                            {attempts < 3 ?
                                <div className="btn-toolbar" style={{ columnGap: 30 }}>
                                    <Button variant="primary" onClick={this.handleCloseabsent}>
                                        <i className="fas fa-arrow-circle-left"></i> Retry Same Position
                                    </Button>
                                    <Button variant="success" onClick={this.renderSummary}>
                                        Calibrate TiM  <i className="fas fa-cogs"></i>
                                    </Button>
                                </div>
                                :
                                <Button variant="success" onClick={this.renderSummary}>
                                    Done <i className="fas fa-check-circle"></i>
                                </Button>
                            }
                        </Modal.Footer>
                    </Modal >
                </div >
                break;
            case 'sessiontimeout':
                componet = < div >
                    < Modal show={show} backdrop="static" size="md" aria-labelledby="contained-modal-title-vcenter" centered onHide={this.handleClose} >
                        <Modal.Body>
                            <h4 style={{ textAlign: "center", color: "Gold" }}><i className="fas fa-exclamation-triangle"></i> WARNING : Request Attempt - {attemptsOfrequest}/{maxattemptsOfrequest} </h4>
                            <h4 style={{ textAlign: "center", color: "Gold" }}>Request timeout</h4>
                        </Modal.Body>
                        <Modal.Footer>
                            {attemptsOfrequest < 3 ?
                                <Button variant="primary" onClick={this.handleCloserequestdeny}>
                                    <i className="fas fa-arrow-circle-left"></i> Retry Same Position
                                </Button>
                                :
                                <Button variant="success" onClick={this.renderSummary}>
                                    Done <i className="fas fa-check-circle"></i>
                                </Button>
                            }
                        </Modal.Footer>
                    </Modal >
                </div >
                break;
            default:
                break;
        }
        return componet;
    }

    readyconfirm() {
        this.setState({ ready: false }, () => {
        });
    }

    componentDidMount() {
        let savedMarker = JSON.parse(localStorage.getItem("savedMarker"));
        console.log(savedMarker);
    }

    render() {
        const { minutes, seconds, statusofcount, postion, ready, postionnumber } = this.state;
        return (
            <div >
                <h4 style={{ textAlign: "center", color: "DodgerBlue", marginTop: 40, }}>Please sit down at position number {postion[postionnumber].id} shown in the image below and confirm when ready.</h4>
                {ready === true ?
                    <ButtonToolbar className="justify-content-center" style={{ columnGap: 30, marginTop: 30 }}>
                        <button type="button" className="btn genericButton  btn-lg btn-success rounded" onClick={() => this.readyconfirm()}> Ready <i className="fas fa-check-circle"></i></button>
                    </ButtonToolbar>
                    :
                    <ButtonToolbar className="justify-content-center" style={{ columnGap: 30, marginTop: 30 }}>
                        <button type="button" className="btn genericButton  btn-lg btn-primary rounded" onClick={() => this.changeState()}><i className='fas fa-street-view'></i> I am at position {postion[postionnumber].id} </button>
                    </ButtonToolbar>
                }
                {minutes === 0 && seconds === 0 ? this.renderontimerexpired(statusofcount) : this.rendertimer()}
                <div className="container">
                    <img src={MR} alt="" height="500px" style={{ padding: 20, opacity: 1 }} ></img>
                    <div className="middle">
                        <svg style={{ width: 500, height: 500 }}>
                            <g>
                                <circle style={{ cx: postion[postionnumber].coordinates.x, cy: postion[postionnumber].coordinates.y, r: 25 }} stroke="black" strokeWidth="3" fill="DeepSkyBlue" opacity="0.7" />
                                <text fill="red" fontWeight="bold" x={postion[postionnumber].coordinates.x - 5} y={postion[postionnumber].coordinates.y + 5} >{postion[postionnumber].id}</text>
                            </g>
                        </svg>
                        <div className="middle">
                            <img src={Grid} alt="" height="450px" style={{ transform: `rotate(${-localStorage.getItem('rotation')}deg)`, opacity: 0.5 }}></img>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default test;