/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import Form from "react-bootstrap/Form";
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
class EnterFloorId extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Floor_ID: '',
    };
  }

  next() {
    this.props.setroute('Devicetable');
    this.props.setfloorId(this.state.Floor_ID);
  }

  logout() {
    this.props.setroute('Login');
  }

  validate() {
    const { Floor_ID } = this.state;
    if (Floor_ID.length > 0) {
      return true;
    }
  }

  renderenterFloorID() {
    return (
      < div >
        <h3 style={{ textAlign: "center", color: "red", marginTop: 40, }}>Please Enter The Floor ID To Generate Device Report</h3>
        <div className="mx-sm-3 mb-2" style={{ textAlign: "center", marginTop: 30, marginBottom: 20 }} >
          <div className="login">
            <Form onSubmit={this.handleSubmit}>
              <Form.Group >
                <Form.Label>Foor ID:
                  <Form.Control type="number" autoComplete="off" onChange={(e) => this.setState({ Floor_ID: e.target.value })} placeholder="Enter floor ID here" />
                </Form.Label>
              </Form.Group>
            </Form>
          </div>
          <ButtonToolbar className="justify-content-center" style={{ columnGap: 30 }}>
            <button type="button" className="btn genericButton  btn-lg btn-danger rounded" onClick={() => this.logout()}><i className="fas fa-arrow-circle-left"></i> Logout</button>
            <button type="button" className="btn genericButton  btn-lg btn-success rounded" disabled={!this.validate()} onClick={() => this.next()}>Next <i className="fas fa-arrow-alt-circle-right"></i></button>
          </ButtonToolbar>
        </div>
      </div >
    )
  }



  render() {


    return (
      <div>
        {this.renderenterFloorID()}
      </div>
    )
  }
}

export default EnterFloorId;


