import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import Login from './componets/loginpage';
import EnterFloorId from './componets/enterFloorId';
import Devicetable from './componets/devicetable';
import XYverification from './componets/checkXY'
import PIRorientation from './componets/checkPIRorientation'
import DragAndDropPostion from './componets/markerpg'
import CalibrateTiM from './componets/calibrateTiM'
import Summary from './componets/summary'
import Test from './componets/occupied'
import Card from 'react-bootstrap/Card'


class App extends React.Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var dateTime = date
    this.state = {
      time: new Date(),
      dateTime: dateTime,
      buldingname: 'Sign in to start your session',
      pageroute: 'Login',
      floorId: null,
      spaceId: null,
    }
  }

  setroute = (gotopath) => {
    this.setState(
      {
        pageroute: gotopath
      }
    )
  }

  setfloorId = (id) => {
    this.setState(
      {
        floorId: id
      }
    )
  }

  setspaceId = (spaceIdSlected) => {
    console.log(spaceIdSlected)
    this.setState(
      {
        spaceId: spaceIdSlected
      }
    )
  }

  updateBuldingname = (name) => {
    this.setState(
      {
        buldingname: name
      }
    )
  }

  componentDidMount() { // create the interval once component is mounted
    this.update = setInterval(() => {
      this.setState({ time: new Date(), });
    }, 1 * 1000); // every 1 seconds
  }

  componentWillUnmount() { // delete the interval just before component is removed
    clearInterval(this.update);
  }

  routehandlying(pagekey) {
    let componet = "";
    switch (pagekey) {
      case 'Login':
        componet = <Login setroute={this.setroute} />
        break;
      case 'EnterfloorID':
        componet = <EnterFloorId setroute={this.setroute} setfloorId={this.setfloorId} updateBuldingname={this.updateBuldingname} />
        break;
      case 'Devicetable':
        componet = <Devicetable setroute={this.setroute} floorId={this.state.floorId} updateBuldingname={this.updateBuldingname} slecteddevice={this.setspaceId} />
        break;
      case 'XYverification':
        componet = <XYverification setroute={this.setroute} updateBuldingname={this.updateBuldingname} m={this.state.spaceId} />
        break;
      case 'PIRorientation':
        componet = <PIRorientation setroute={this.setroute} />
        break;
      case 'DragAndDropPostion':
        componet = <DragAndDropPostion setroute={this.setroute} />
        break;
      case 'CalibrateTiM':
        componet = <CalibrateTiM setroute={this.setroute} />
        break;
      case 'test':
        componet = <Test setroute={this.setroute} />
        break;
      case 'Summary':
        componet = <Summary setroute={this.setroute} updateBuldingname={this.updateBuldingname} />
        break;
      default:
        break;
    }
    return componet;
  }

  render() {
    const { time } = this.state;
    const { dateTime } = this.state;
    return (
      <div>
        <Card style={{ width: '80rem', marginTop: 20, marginLeft: 43, marginBottom: 20 }}>
          <Card.Header as="h4" >
            <div>
              <i className="fas fa-tools"></i> TiM Calibration Tool
            </div>
            <div>
              <h5>{dateTime} | {time.toLocaleTimeString()}</h5>
            </div>
          </Card.Header>
          <Card.Body>
            <Card.Title as="h4" style={{ textAlign: "center", color: "DodgerBlue" }}><i className="fas fa-building"></i> {" "}{this.state.buldingname}</Card.Title>
            <hr></hr>
            {this.routehandlying(this.state.pageroute)}
          </Card.Body>
          <Card.Footer >Freespace</Card.Footer>
        </Card>
      </div >
    );
  }
}

export default App;
