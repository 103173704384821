import React from 'react';
import MR from './MR1.jpg'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

class DragAndDropPostion extends React.Component {

    constructor() {
        super();
        this.state = {
            currentPostion: 0,
            save: false,
            maxpostion: 4,
            circle:
            {
                postion: 1,
                x: 50,
                y: 50
            },
            circletest: [
                {
                    postion: 0,
                    coordinates:
                    {
                        x: 50,
                        y: 50
                    }
                },
                {
                    postion: 1,
                    coordinates:
                    {
                        x: 50,
                        y: 50
                    }
                },
                {
                    postion: 2,
                    coordinates:
                    {
                        x: 50,
                        y: 50
                    }
                },
                {
                    postion: 3,
                    coordinates:
                    {
                        x: 50,
                        y: 50
                    }
                },
                {
                    postion: 4,
                    coordinates:
                    {
                        x: 50,
                        y: 50
                    }
                },
            ]
        };
    }
    Backfrompg() {
        this.props.setroute('PIRorientation');
    }

    confirmMarker() {
        const { currentPostion, circletest, maxpostion } = this.state
        if (currentPostion < maxpostion) {
            const increment = currentPostion + 1;
            this.setState(prevState => ({
                circletest: prevState.circletest.map(
                    obj => (obj.postion === currentPostion ? Object.assign(obj, { coordinates: { x: circletest[currentPostion].coordinates.x, y: circletest[currentPostion].coordinates.y } }) : obj)
                ),
                currentPostion: increment,
                save: true
            }));
        }
    }

    resetall() {
        this.props.setroute('DragAndDropPostion');
    }

    saveAll() {
        const { circletest } = this.state
        localStorage.setItem('savedMarker', JSON.stringify(circletest));
        this.props.setroute('CalibrateTiM');
    }

    renderSavedMarker() {
        var indents = [];
        const { circletest, currentPostion } = this.state
        for (var i = 0; i < currentPostion; i++) {
            indents.push(<g>
                <circle style={{ cx: circletest[i].coordinates.x, cy: circletest[i].coordinates.y, r: 25 }} width="10" height="10" stroke="black" strokeWidth="3" fill="DeepSkyBlue" opacity="0.7" ref={(e) => this.svgRectElem = e} onMouseDown={(e) => this.startDrag(e, this.svgRectElem)} />
                <text fill="red" x={circletest[i].coordinates.x - 5} y={circletest[i].coordinates.y + 5} ref={(e) => this.svgRectElem = e} onMouseDown={(e) => this.startDrag(e, this.svgRectElem)} >{circletest[i].postion}</text>
            </g>);
        }
        return indents;
    }

    render() {
        const { circletest, currentPostion } = this.state
        return (
            <div>
                <h4 style={{ textAlign: "center", color: "DodgerBlue", marginTop: 40, }}>Please sit down at position number shown in the image below and confirm when ready.</h4>
                <ButtonToolbar className="justify-content-center" style={{ columnGap: 30, marginTop: 30 }}>
                    <button type="button" className="btn genericButton  btn-lg btn-danger rounded" onClick={() => this.Backfrompg()}><i className="fas fa-arrow-circle-left"></i> Back</button>
                    <button type="button" className="btn genericButton  btn-lg btn-primary rounded" onClick={() => this.resetall()}>Reset All Marker <i className="fas fa-sync-alt"></i></button>
                    <button type="button" className="btn genericButton  btn-lg btn-primary rounded" onClick={() => this.confirmMarker()}>Create Marker <i className="far fa-dot-circle"></i> </button>
                    <button type="button" className="btn genericButton  btn-lg btn-success rounded" onClick={() => this.saveAll()}>Save All <i className="far fa-dot-circle"></i></button>
                </ButtonToolbar>
                <div className="container">
                    <img src={MR} alt="" height="500px" style={{ padding: 20, opacity: 1 }} ></img>
                    <div className="middle1">
                        <svg style={{ width: 1000, height: 500 }} ref={(svg) => this.svg = svg}>
                            <g>
                                <circle style={{ cx: circletest[currentPostion].coordinates.x, cy: circletest[currentPostion].coordinates.y, r: 25 }} width="10" height="10" stroke="black" strokeWidth="3" fill="DeepSkyBlue" opacity="0.7" ref={(e) => this.svgRectElem = e} onMouseDown={(e) => this.startDrag(e, this.svgRectElem)} />
                                <text fill="red" x={circletest[currentPostion].coordinates.x - 5} y={circletest[currentPostion].coordinates.y + 5} ref={(e) => this.svgRectElem = e} onMouseDown={(e) => this.startDrag(e, this.svgRectElem)} >{circletest[currentPostion].postion}</text>
                            </g>
                            {this.state.save ?
                                <g>
                                    {this.renderSavedMarker()}
                                </g>
                                :
                                ""
                            }
                        </svg>
                    </div>
                </div>
                Position: {circletest[currentPostion].postion}<br />
                X: {circletest[currentPostion].coordinates.x} <br />
                Y: {circletest[currentPostion].coordinates.y}
            </div >
        );
    }

    startDrag(event, draggedElem) {
        const { circletest, currentPostion } = this.state;
        event.preventDefault();
        let point = this.svg.createSVGPoint();
        point.x = event.clientX;
        point.y = event.clientY;
        point = point.matrixTransform(this.svg.getScreenCTM().inverse());
        this.setState({
            dragOffset: {
                x: point.x - circletest[currentPostion].coordinates.x,
                y: point.y - circletest[currentPostion].coordinates.y,
                postion: circletest[currentPostion].postion
            }
        });

        const mousemove = (event) => {
            event.preventDefault();
            point.x = event.clientX;
            point.y = event.clientY;
            let cursor = point.matrixTransform(this.svg.getScreenCTM().inverse());
            const X = cursor.x - this.state.dragOffset.x;
            const Y = cursor.y - this.state.dragOffset.y
            this.setState(prevState => ({
                circletest: prevState.circletest.map(
                    obj => (obj.postion === this.state.currentPostion ? Object.assign(obj, { coordinates: { x: X, y: Y } }) : obj)
                )
            }));
        };

        const mouseup = (event) => {
            document.removeEventListener("mousemove", mousemove);
            document.removeEventListener("mouseup", mouseup);
        };

        document.addEventListener("mousemove", mousemove);
        document.addEventListener("mouseup", mouseup);
    }
}


export default DragAndDropPostion;