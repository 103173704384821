import React from 'react';
import TiMdeviceicon from './tim-icon-2.svg'
import MR from './MR1.jpg'
import Grid from './grid -view.svg'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
class PIRorientation extends React.Component {
    constructor() {
        super();
        this.state = {
            showPIRverfiymodal: true,
            rotation: 0
        }
        this.rotate = this.rotate.bind(this);
        this.rotateleft = this.rotateleft.bind(this);
        this.resetrotation = this.resetrotation.bind(this);
    }

    rotate() {
        let newRotation = this.state.rotation - 15;
        if (newRotation < -360) {
            newRotation = 0;
        }
        console.log(newRotation)
        localStorage.setItem('rotation', newRotation);
        this.setState({ rotation: newRotation, }, () => {
        });
    }

    rotateleft() {
        let newRotation = this.state.rotation + 15;
        if (newRotation > 360) {
            newRotation = 0;
        }
        console.log(newRotation)
        localStorage.setItem('rotation', newRotation);
        this.setState({ rotation: newRotation, }, () => {
        });
    }

    resetrotation() {
        let newRotation = 0;
        localStorage.setItem('rotation', newRotation);
        this.setState({ rotation: newRotation }, () => {
        });
    }

    confirmOrientation() {
        this.props.setroute('DragAndDropPostion');
    }

    Backfrompg() {
        this.props.setroute('XYverification');
    }

    showPIRverfiymodal() {
        return (
            <div>
                <h3 style={{ textAlign: "center", color: "DodgerBlue", marginTop: 40 }}>PIR Orientation verification</h3>
                <ButtonToolbar className="justify-content-center" style={{ columnGap: 30, marginTop: 30 }}>
                    <button type="button" className="btn genericButton  btn-lg btn-danger rounded" onClick={() => this.Backfrompg()}><i className="fas fa-arrow-circle-left"></i> Back</button>
                    <button type="button" className="btn genericButton  btn-lg btn-success rounded" onClick={() => this.confirmOrientation()}>Confirm Orientation <i className="fas fa-clipboard-check"></i></button>
                </ButtonToolbar>
                <div className="row" style={{ marginTop: 40, marginBottom: 20 }}>
                    <div className="col">
                        <img style={{ transform: `rotate(${-localStorage.getItem('rotation')}deg)`, marginLeft: 510, }} src={TiMdeviceicon} alt="icon" height="100px"></img>
                    </div>
                    <div className="col" >
                        <div className="btn-group-vertical" >
                            <button style={{ marginBottom: 8 }} type="button" className="btn genericButton  btn-sm btn-info rounded" onClick={this.rotateleft}><i className="fas fa-undo-alt"></i></button>
                            <button style={{ marginBottom: 8 }} type="button" className="btn genericButton  btn-sm btn-info rounded" onClick={this.rotate}><i className="fas fa-redo-alt"></i></button>
                            <button type="button" className="btn genericButton  btn-sm btn-info rounded" onClick={this.resetrotation}>Reset <i className="fas fa-sync-alt"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // componentDidMount() {
    //     let newRotation = localStorage.getItem('rotation');
    //     this.setState(
    //         {
    //             rotation: newRotation
    //         }
    //     )
    // }

    render() {
        return (
            <div>
                {this.state.showPIRverfiymodal ? this.showPIRverfiymodal() : ""}
                <div className="container">
                    <img src={MR} alt="" height="500px" style={{ padding: 20, opacity: 1 }} ></img>
                    <div className="middle">
                        {/* <img style={{ transform: `rotate(${localStorage.getItem('rotation')}deg)` }} src={TiMdeviceicon} alt="" height="50px"></img> */}
                        <img src={Grid} alt="" height="450px" style={{ transform: `rotate(${-localStorage.getItem('rotation')}deg)`, opacity: 0.5 }}></img>
                    </div>
                </div>
            </div>
        )
    }
}

export default PIRorientation;