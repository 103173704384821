import React from 'react';
import MR from './MR1.jpg'
import Grid from './grid -view.svg'
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'


const auth_key = "12/U/362/1623760697332/e9657e8aed59cc89e9ca10ea34fad418";
class XYverification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            insertX: 10,
            insertY: 10,
            inserthight: 2.8,
            unitType: "m",
            deviceDimension: {
                X: (10).toFixed(2),
                Y: (10).toFixed(2),
                Height: (3).toFixed(2),
            },
            XYverify: true,
            editXY: false,
            dimension: []
        }
    }

    Backfrompg() {
        this.props.setroute('Devicetable');
    }

    editXY() {
        this.setState({
            XYverify: false,
            editXY: true
        })
    }

    backfrominsertXY() {
        this.setState({
            XYverify: true,
            editXY: false
        })
    }

    ConfirmXY = () => {
        const { deviceDimension, unitType } = this.state
        localStorage.setItem('X', deviceDimension.X);
        localStorage.setItem('Y', deviceDimension.Y);
        localStorage.setItem('Height', deviceDimension.Height);
        localStorage.setItem('uintType', unitType)
        this.props.setroute('PIRorientation');
    }

    callApi() {
        const { spaceId } = this.props;
        this.setState({ ...this.state });
        fetch(`https://tim-ws.afreespace.com/api/config/space/${spaceId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-auth-key': auth_key
            }
        })
            .then(resp => {
                return resp.json();
            })
            .then(Response => {
                this.setState({
                    dimension: Response
                })
                console.log(this.state.dimension)
            })
            .catch(console.log)
    }

    setdimension() {
        console.log(this.state.dimension)
        let xlocalStorage = localStorage.getItem('X');
        let ylocalStorage = localStorage.getItem('Y');
        let Heightstorage = localStorage.getItem('Height');
        let UintType = localStorage.getItem('uintType');
        if (UintType === "m") {

        }
        else if (UintType === "ft") {
            xlocalStorage = (xlocalStorage / 3.281).toFixed(2);
            ylocalStorage = (ylocalStorage / 3.281).toFixed(2);
            Heightstorage = (Heightstorage / 3.281).toFixed(2);
            UintType = "m"
        }
        this.setState({ deviceDimension: { X: xlocalStorage, Y: ylocalStorage, Height: Heightstorage, }, unitType: UintType })
    }

    componentDidMount() {
        //this.callApi();
        this.setdimension();
    }

    onChangeX = (event) => {
        this.setState({ insertX: event.target.value })
    }

    onChangeY = (event) => {
        this.setState({ insertY: event.target.value })
    }

    onChangehight = (event) => {
        this.setState({ inserthight: event.target.value })
    }

    oninsert = (event) => {
        this.setState({ XYverify: true, editXY: false, deviceDimension: { X: this.state.insertX, Y: this.state.insertY, Height: this.state.inserthight } })
    }

    renderinsertXY() {
        const { unitType } = this.state
        console.log(this.state.dimension)
        return (
            <div>
                <h3 style={{ textAlign: "center", color: "DodgerBlue", marginTop: 10 }}>Please Enter Physical Distance of X and Y of device from wall and Height from floor </h3>
                <div className="Login">
                    <Form>
                        <Form.Group as={Row} size="md">
                            <Form.Label as='h4' style={{ textAlign: "center", color: "Blue" }}>X :</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    autoComplete="off"
                                    type="number"
                                    value={this.state.email}
                                    placeholder="Enter X value from wall here "
                                    onChange={this.onChangeX}
                                />
                            </Col>
                            <h4 style={{ textAlign: "center", color: "Blue" }}>{unitType}</h4>
                        </Form.Group>
                        <Form.Group as={Row} size="md">
                            <Form.Label as='h4' style={{ textAlign: "center", color: "Blue" }}>Y :</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    autoComplete="off"
                                    type="number"
                                    value={this.state.password}
                                    placeholder="Enter Y value from wall here "
                                    onChange={this.onChangeY}
                                />
                            </Col>
                            <h4 style={{ textAlign: "center", color: "Blue" }}>{unitType}</h4>
                        </Form.Group>
                        <Form.Group as={Row} size="md">
                            <Form.Label as='h4' style={{ textAlign: "center", color: "Blue" }}>H :</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    autoComplete="off"
                                    type="number"
                                    value={this.state.password}
                                    placeholder="Enter Height from floor here"
                                    onChange={this.onChangehight}
                                />
                            </Col>
                            <h4 style={{ textAlign: "center", color: "Blue" }}>{unitType}</h4>
                        </Form.Group>
                    </Form>
                </div>
                <ButtonToolbar className="justify-content-center" style={{ columnGap: 60 }}>
                    <button type="button" className="btn genericButton  btn-lg btn-danger rounded" onClick={() => this.backfrominsertXY()}><i className="fas fa-arrow-circle-left"></i> Back</button>
                    <button type="button" className="btn genericButton  btn-lg btn-success rounded" onClick={() => this.oninsert()}>Insert <i className="fas fa-clipboard-check"></i></button>
                </ButtonToolbar>
            </div >
        )
    }

    changeunittoft = () => {
        const { deviceDimension, unitType } = this.state
        if (unitType === 'm') {
            const xinft = (deviceDimension.X * 3.281).toFixed(2);
            const yinft = (deviceDimension.Y * 3.2810).toFixed(2);
            const hightinft = (deviceDimension.Height * 3.281).toFixed(2);
            this.setState({ deviceDimension: { X: xinft, Y: yinft, Height: hightinft }, unitType: "ft" }, () => {
            });
        }

    }

    changeunittom = () => {
        const { deviceDimension, unitType } = this.state
        if (unitType === 'ft') {
            const xinft = (deviceDimension.X / 3.281).toFixed(2);
            const yinft = (deviceDimension.Y / 3.281).toFixed(2);
            const hightinft = (deviceDimension.Height / 3.281).toFixed(2);
            this.setState({ deviceDimension: { X: xinft, Y: yinft, Height: hightinft }, unitType: "m" }, () => {
            });
        }
    }

    renderXYverify() {
        const { deviceDimension, unitType } = this.state
        return (
            <div>
                <h4 style={{ textAlign: "center", color: "red", marginTop: 20 }}>X  : {deviceDimension.X} {unitType} | Y  : {deviceDimension.Y} {unitType}</h4>
                <h4 style={{ textAlign: "center", color: "red" }}>Height   : {deviceDimension.Height} {unitType}</h4>
                <ButtonToolbar className="justify-content-center" style={{ columnGap: 30, marginTop: 20 }}>
                    <button type="button" className="btn genericButton  btn-lg btn-danger rounded" onClick={() => this.Backfrompg()}><i className="fas fa-arrow-circle-left"></i> Back</button>
                    <button type="button" className="btn genericButton  btn-lg btn-primary rounded" onClick={() => this.editXY()} >Edit location <i className="fas fa-edit"></i></button>
                    <button type="button" className="btn genericButton  btn-lg btn-success rounded" onClick={() => this.ConfirmXY()}>Confirm location <i className="fas fa-clipboard-check"></i></button>
                </ButtonToolbar>
            </div >
        )
    }

    render() {
        return (
            <div>
                <div>
                    <h3 style={{ textAlign: "center", color: "DodgerBlue", marginTop: 40 }}>Please verify Physical location Of The Device in : <input type="radio" name="Unit" value="m" onClick={this.changeunittom} defaultChecked /> m <input type="radio" name="Unit" value="ft" onClick={this.changeunittoft} /> ft</h3>
                    {this.state.XYverify ? this.renderXYverify() : ""}
                    {this.state.editXY ? this.renderinsertXY() : ""}
                    <div className="container">
                        <img src={MR} alt="" height="500px" style={{ padding: 20, opacity: 1 }} ></img>
                        <div className="middle">
                            <img src={Grid} alt="" height="450px" style={{ transform: `rotate(${localStorage.getItem('rotation')}deg)`, opacity: 0.5 }}></img>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default XYverification;


