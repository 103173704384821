import React from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validateForm() {
        const { email } = this.state;
        const { password } = this.state;
        if (email.length > 0 && password.length > 0) {
            return true;
        }
        else {
            //console.log("Enter valid email id");
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.setroute('EnterfloorID');
    }
    render() {
        return (
            <div className="Login" style={{ marginBottom: 20 }}>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group size="lg" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            autoFocus
                            type="email"
                            value={this.state.email}
                            placeholder="name@afreespace.com"
                            onChange={(e) => this.setState({ email: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group size="lg" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={this.state.password}
                            placeholder="Password"
                            onChange={(e) => this.setState({ password: e.target.value })}
                        />
                    </Form.Group>
                    <Button block size="lg" type="submit" disabled={!this.validateForm()}>
                        <i className="fas fa-sign-in-alt"></i> Login
                    </Button>
                </Form>
            </div>
        )
    }
}
export default Login;