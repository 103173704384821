import React from 'react';
import Table from 'react-bootstrap/Table'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
class summary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    };

    nextroom() {
        this.props.updateBuldingname('Kamala Mills, Mumbai Level 6')
        this.props.setroute('Devicetable');
    }

    Backfrompg() {
        this.props.setroute('test');
    };


    render() {
        return (
            <div>
                <h3 style={{ textAlign: "center", color: "DodgerBlue", marginTop: 30, }}>Summary</h3>
                <Table bordered hover style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Postion No.</th>
                            <th scope="col">Calibartion Status</th>
                            <th scope="col">Count</th>
                            <th scope="col">coordinates</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr>
                            <td>1</td>
                            <td style={{ color: "green" }}> SUCCESSFUL</td>
                            <td>1</td>
                            <td>X:  Y:</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td style={{ color: "green" }}> SUCCESSFUL</td>
                            <td>1</td>
                            <td>X:  Y:</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td style={{ color: "green" }}> SUCCESSFUL</td>
                            <td>1</td>
                            <td>X:  Y:</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td style={{ color: "green" }}> SUCCESSFUL</td>
                            <td>1</td>
                            <td>X:  Y:</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td style={{ color: "green" }}> SUCCESSFUL</td>
                            <td>1</td>
                            <td>X:  Y:</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td style={{ color: "green" }}> SUCCESSFUL</td>
                            <td>1</td>
                            <td>X:  Y:</td>
                        </tr>
                    </tbody>
                </Table>
                <ButtonToolbar className="justify-content-center" style={{ columnGap: 30, marginTop: 30 }}>
                    <button type="button" className="btn genericButton  btn-lg btn-danger rounded" onClick={() => this.Backfrompg()}><i className="fas fa-arrow-circle-left"></i> Back</button>
                    <button type="button" className="btn genericButton  btn-lg btn-primary rounded" onClick={() => this.nextroom()}>Save & Exit <i className="fas fa-person-booth"></i></button>
                </ButtonToolbar>
            </div>
        )
    }
}

export default summary;